<template>
  <change-account-statuses
    :showDialog.sync="show"
    :theme="theme"
    multipleInvoices="true"
    :totalInvoicesAffectedText="totalInvoicesAffectedText"
    :newInvoiceStatus.sync="newStatus"
    :note.sync="cancelationNote"
    :dropdownItems="dropdownItems"
    :save="save"
    :resetNote="resetNote"
    :changedNote="changedNote"
    :changedStatus="changedStatus"
  ></change-account-statuses>
</template>

<script>
import { mapState } from "vuex";
import ChangeAccountStatuses from "./ChangeAccountStatuses.vue";

export default {
  components: { ChangeAccountStatuses },
  props: [
    "showDialog",
    "theme",
    "totalInvoicesAffected",
    "totalLineItems",
    "lineItems",
    "selectedInvoices",
    "allSelected"
  ],
  emits: ["update:showDialog", "confirm"],
  data: () => ({
    mutualStatus: null,
    newStatus: null,
    dropdownItems: [],
    oldCancelationNote: null,
    cancelationNote: null,
  }),
  computed: {
    ...mapState("accountStatus", ["billingStatusTypes"]),
    show: {
      get() {
        return this.showDialog;
      },
      set(newValue) {
        this.$emit("update:showDialog", newValue);
      }
    },
    changedStatus() {
      if (
        this.newStatus &&
        this.newStatus.value !== null &&
        this.mutualStatus &&
        this.mutualStatus.value !== this.newStatus.value
      ) {
        return true;
      }
      return false;
    },
    changedNote() {
      if (!this.cancelationNote || this.cancelationNote.trim().length === 0) {
        return false;
      }
      return this.cancelationNote.trim() !== this.oldCancelationNote;
    },
    totalInvoicesAffectedText() {
      if (this.totalInvoicesAffected == 1) {
        return "1 invoices affected";
      } else {
        return `${this.totalInvoicesAffected} invoices affected`;
      }
    },
  },
  watch: {
    show(val) {
      if (val) {
        this.setDropdownItems();
        this.setMutualStatus();
        this.setCancelationNote();
        this.newStatus = this.mutualStatus;
      }
    }
  },
  methods: {
    async save() {
      let payload = {
        newStatus: this.newStatus.value,
        note: this.cancelationNote
      };
      this.$emit("confirm", payload);
    },
    setDropdownItems() {
      this.dropdownItems = [];
      this.billingStatusTypes.forEach(element => {
        this.dropdownItems.push(element);
      });
    },
    addMixedToDropdownItems() {
      this.dropdownItems.push({ displayName: "(Mixed)", value: null });
    },
    setMutualStatus() {
      if (this.allSelected) {
        if (this.totalLineItems > this.lineItems.length) {
          this.mutualStatus = { displayName: "(Mixed)" };
          this.addMixedToDropdownItems();
        } else {
          this.mutualStatus = this.getMutualStatus(this.lineItems);
        }
      } else {
        this.mutualStatus = this.getMutualStatus(this.selectedInvoices);
      }
      this.addValueToMutualStatus();
    },
    getMutualStatus(invoices) {
      let statuses = invoices.map(a => a.status);
      let uniqueStatuses = new Set(statuses);

      if (uniqueStatuses.size == 0 || uniqueStatuses.has("")) {
        return null;
      }

      if (uniqueStatuses.size == 1) {
        return { displayName: statuses[0] };
      }

      this.addMixedToDropdownItems();
      return { displayName: "(Mixed)" };
    },
    addValueToMutualStatus() {
      if (!this.mutualStatus) {
        return;
      }
      let i = 0;
      let status = null;
      while (i < this.dropdownItems.length && status == null) {
        let statusType = this.dropdownItems[i];
        if (statusType.displayName === this.mutualStatus.displayName) {
          status = statusType;
        }
        i++;
      }
      this.mutualStatus = status;
    },
    setCancelationNote() {
      if (this.selectedInvoices.length == 1 && this.selectedInvoices[0].note) {
        this.oldCancelationNote = this.selectedInvoices[0].note.trim();
        this.cancelationNote = this.selectedInvoices[0].note.trim();
      } else {
        this.oldCancelationNote = null;
        this.cancelationNote = null;
      }
    },
    resetNote() {
      this.cancelationNote = null;
    },
  },
};
</script>
