<template>
  <info-dialog
    message="No data for export, no files generated"
    :showDialog.sync="show"
  ></info-dialog>
</template>

<script>
import { mapState } from "vuex";
import InfoDialog from "@/components/dialogs/InfoDialog.vue";
export default {
  components: { InfoDialog },
  props: ["showDialog"],
  emits: ["update:showDialog"],
  data: () => ({}),
  computed: {
    ...mapState("userConfig", ["colorTheme"]),
    show: {
      get() {
        return this.showDialog;
      },
      set(newValue) {
        this.$emit("update:showDialog", newValue);
      }
    }
  },
  methods: {
    close() {
      this.$emit("update:showDialog", false);
    }
  }
};
</script>

<style scoped>
.right-corner {
  position: absolute;
  top: 0;
  right: 0.5em;
}
</style>
