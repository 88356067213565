<template>
  <v-dialog v-model="show" width="unset" @keydown.esc="close">
    <v-card :color="colorTheme.warning" dark>
      <v-icon class="right-corner exit-icon mr-1" @click="close">
        mdi-close
      </v-icon>
      <div class="pt-2">
        <v-card-text class="text-center">
          {{ message }}
        </v-card-text>
      </div>
      <v-btn
        block
        :style="textColor"
        :color="colorTheme.buttonWhite"
        @click="close"
      >
        OK
      </v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["showDialog", "message"],
  emits: ["update:showDialog"],
  data: () => ({}),
  computed: {
    ...mapState("userConfig", ["colorTheme"]),
    show: {
      get() {
        return this.showDialog;
      },
      set(newValue) {
        this.$emit("update:showDialog", newValue);
      }
    },
    textColor() {
      return this.colorTheme.warningTextColor;
    }
  },
  methods: {
    close() {
      this.$emit("update:showDialog", false);
    }
  }
};
</script>

<style scoped>
.right-corner {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
