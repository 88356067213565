<template>
  <v-container fluid>
    <empty-exports-dialog
      :showDialog.sync="showEmptyExportsDialog"
    ></empty-exports-dialog>
    <error-dialog
      message="Export error!"
      :showDialog.sync="showExportErrorDialog"
    ></error-dialog>
    <success-dialog
      :message="exportStartedMessage"
      :showDialog.sync="exportStarted"
    ></success-dialog>
    <warning-dialog
      :message="exportWarningMessage"
      :showDialog.sync="exportWarningDialog"
    ></warning-dialog>
    <info-dialog
      :message="exportInfoMessage"
      :showDialog.sync="showExportInfoMessage"
    ></info-dialog>
    <info-dialog
      :message="updateMessage"
      :showDialog.sync="showUpdateInfo"
    ></info-dialog>
    <running-dialog
      message="Updating statuses. Please wait..."
      :showDialog.sync="updatingStatuses"
    ></running-dialog>
    <error-dialog
      message="Update failed"
      :showDialog.sync="showUpdateErrorMessage"
    ></error-dialog>
    <success-dialog
      message="Updated successfully"
      :showDialog.sync="updateSuccess"
    ></success-dialog>
    <change-multiple-account-statuses
      :showDialog.sync="showUpdateDialog"
      :theme="theme"
      :totalInvoicesAffected="totalSelected"
      :totalLineItems="totalLineItems"
      :lineItems="lineItems"
      :selectedInvoices="selected"
      :allSelected="allSelected"
      @confirm="confirmUpdate"
    ></change-multiple-account-statuses>
    <v-card>
      <v-card-title> Account Status </v-card-title>
      <v-data-table
        fixed-header
        :class="{ 'all-selected': allSelected, 'scrollable-table__large': true }"
        :headers="headers"
        :items="lineItems"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalLineItems"
        item-key="id"
        :single-select="false"
        show-select
        v-model="selected"
        :footer-props="{
          'items-per-page-options': rowsPerPage,
          disablePagination: loading,
          disableItemsPerPage: loading,
        }"
      >
        <template v-if="allSelected" v-slot:[`header.data-table-select`]="{}">
          <v-simple-checkbox :value="true" disabled />
        </template>
        <template v-if="allSelected" v-slot:[`item.data-table-select`]="{}">
          <v-simple-checkbox :value="true" disabled />
        </template>
        <template v-slot:progress>
          <v-progress-linear
            :color="theme.progress"
            indeterminate
          ></v-progress-linear>
        </template>
        <template v-slot:[`item.feeAmount`]="{ item }">
          <cell-monetary-value :value="item.feeAmount" />
        </template>
        <template v-slot:[`item.priorUnpaid`]="{ item }">
          <cell-monetary-value :value="item.priorUnpaid" />
        </template>
        <template v-slot:[`item.totalAmount`]="{ item }">
          <cell-monetary-value :value="item.totalAmount" />
        </template>
        <template v-slot:[`item.sourceAccountId`]="{ item }">
          <cell-clipboard :text="item.sourceAccountId" />
        </template>
        <template v-slot:[`item.paidByAccountId`]="{ item }">
          <cell-clipboard :text="item.paidByAccountId" />
        </template>
        <template v-slot:[`item.billingRunType`]="{ item }">
          <span v-if="item.billingRunType">
            {{ formatBillingRunType(item.billingRunType) }}
          </span>
        </template>
      </v-data-table>
      <v-row justify="space-between" class="ml-0 mr-0">
        <span class="number-effect_space">
          <v-row>
            <v-col cols="12" md="8">
              <v-card-text class="py-0 mx-1 my-1">
                Items selected:
                <span :class="activeEffect && 'number-effect'">
                  {{ totalSelected }}
                </span>
              </v-card-text>
            </v-col>
            <v-col cols="12" md="4">
              <v-switch
                class="py-0 mx-1 my-1"
                label="ALL"
                v-model="allSelected"
                :color="theme.switch"
              ></v-switch>
            </v-col>
          </v-row>
        </span>
      </v-row>
      <v-row justify="space-between" class="ml-0 mr-0">
        <span>
          <v-btn class="mb-2 ml-2" :color="theme.button" dark @click="back">
            Back
          </v-btn>
          <v-btn
            class="mb-2 ml-2"
            :color="theme.button"
            :dark="!buttonDisabled"
            @click="selectNone"
            :disabled="buttonDisabled"
          >
            Select None
          </v-btn>
        </span>
        <span>
          <v-btn
            class="mb-2 ml-2"
            :color="theme.button"
            :dark="!buttonLimits"
            @click="updateBillingStatuses"
            :disabled="buttonLimits"
          >
            Status Update
          </v-btn>
          <v-btn
            class="mb-2 ml-2"
            :color="theme.button"
            :dark="!buttonLimits"
            @click="generateCustodian"
            :disabled="buttonLimits"
          >
            Send to Custodian
          </v-btn>
          <v-btn
            class="mb-2 ml-2"
            :color="theme.button"
            :dark="!buttonLimits"
            @click="generatePayout"
            :disabled="buttonLimits"
          >
            Payout
          </v-btn>
          <v-btn
            class="mb-2 ml-2 mr-2"
            :color="theme.button"
            :dark="!historyButtonDisabled"
            @click="showInvoiceHistory"
            :disabled="historyButtonDisabled"
          >
            Show Invoice History
          </v-btn>
        </span>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { getPreviousRunIntFromString } from "@/utils/period-util.js";
import {
  formatMonetaryValue,
  formatBillingRunType
} from "@/utils/format-util.js";
import amplitude from 'amplitude-js'

import EmptyExportsDialog from "@/components/fee-results/dialogs/EmptyExportsDialog.vue";
import CellClipboard from "@/components/common/CellClipboard.vue";
import CellMonetaryValue from "@/components/common/CellMonetaryValue.vue";
import ErrorDialog from "@/components/dialogs/ErrorDialog.vue";
import InfoDialog from "@/components/dialogs/InfoDialog.vue";
import RunningDialog from "@/components/dialogs/RunningDialog.vue";
import SuccessDialog from "@/components/dialogs/SuccessDialog.vue";
import WarningDialog from "@/components/dialogs/WarningDialog.vue";
import ChangeMultipleAccountStatuses from "./dialogs/ChangeMultipleAccountStatuses.vue";

export default {
  components: {
    EmptyExportsDialog,
    CellClipboard,
    CellMonetaryValue,
    ErrorDialog,
    InfoDialog,
    RunningDialog,
    SuccessDialog,
    WarningDialog,
    ChangeMultipleAccountStatuses
  },
  data: () => ({
    activeEffect: false,
    showUpdateErrorMessage: false,
    updateSuccess: false,
    updatingStatuses: false,
    updateMessage: "",
    showUpdateInfo: false,
    showUpdateDialog: false,
    loading: false,
    showEmptyExportsDialog: false,
    showExportErrorDialog: false,
    exportStarted: false,
    exportStartedMessage: "",
    exportWarningDialog: false,
    exportWarningMessage: "",
    showExportInfoMessage: false,
    exportInfoMessage: "",
    headers: [
      {
        text: "Account ID",
        value: "sourceAccountId",
        align: "center",
        sortable: false,
      },
      {
        text: "Account Name",
        value: "name",
        sortable: false,
      },
      {
        text: "Paid By Account ID",
        value: "paidByAccountId",
        align: "center",
        sortable: false,
      },
      {
        text: "Paid By",
        value: "paidBy",
        sortable: false,
      },
      {
        text: "Fee Amount",
        value: "feeAmount",
        align: "end",
        sortable: false,
      },
      {
        text: "Prior Unpaid",
        value: "priorUnpaid",
        align: "end",
        sortable: false,
      },
      {
        text: "Total Due",
        value: "totalAmount",
        align: "end",
        sortable: false,
      },
      {
        text: "Status",
        value: "status",
        sortable: false,
      },
      {
        text: "Billing Frequency",
        value: "billingFrequency",
        sortable: false,
      },
      {
        text: "Custodian",
        value: "custodian",
        sortable: false,
      },
      {
        text: "Billing Run Type",
        value: "billingRunType",
        sortable: false,
      },
    ],
  }),
  computed: {
    ...mapState("feeResultsAccounts", [
      "feeResultsAllSelected",
      "selectedAccounts",
      "selectedDate",
      "maxItems",
    ]),
    ...mapState("userConfig", ["colorTheme", "colorThemeAll", "rowsPerPage"]),
    ...mapState("accountStatus", [
      "lineItems",
      "totalLineItems",
      "totalSelectedLineItems",
      "lineItemsAllSelected",
      "selectedLineItems",
      "pagination",
    ]),
    ...mapState("feeResultsRecords", ["accountsForCalculation"]),
    options: {
      get() {
        return this.pagination;
      },
      set(newValue) {
        this.setPagination(newValue);
      },
    },
    page() {
      return this.options.page;
    },
    pageSize() {
      return this.options.itemsPerPage;
    },
    selected: {
      get() {
        return this.selectedLineItems;
      },
      set(newValue) {
        this.setSelectedLineItems(newValue);
      },
    },
    totalSelected: {
      get() {
        if (this.allSelected) {
          return this.totalLineItems;
        } else {
          return this.totalSelectedLineItems;
        }
      },
      set(newValue) {
        this.setTotalSelectedLineItems(newValue);
      },
    },
    allSelected: {
      get() {
        return this.lineItemsAllSelected;
      },
      set(newValue) {
        this.setLineItemsAllSelected(newValue);
      }
    },
    theme() {
      if (this.allSelected) {
        return this.colorThemeAll;
      } else {
        return this.colorTheme;
      }
    },
    buttonDisabled() {
      return this.totalSelected == 0;
    },
    buttonLimits() {
      return (
        this.buttonDisabled ||
        (!this.allSelected && this.totalSelected > this.maxItems)
      );
    },
    historyButtonDisabled() {
      return (this.selected.length != 1 || this.allSelected);
    }
  },
  watch: {
    options: {
      deep: true,
      handler(val, oldVal) {
        if (!this.$cookies.isKey("token")) {
          return;
        }
        if (val.page !== oldVal.page) {
          this.refresh();
        } else if (val.itemsPerPage !== oldVal.itemsPerPage) {
          this.refreshOrCut(val.itemsPerPage);
        }
      },
    },
    totalSelected() {
      if (!this.$cookies.isKey("token")) {
        return;
      }
      this.activeEffect = false;
      setTimeout(() => {
        this.activeEffect = true;
      });
    },
    selected(val) {
      if (!this.$cookies.isKey("token")) {
        return;
      }
      this.totalSelected = val.length;
    },
  },
  methods: {
    ...mapMutations("accountStatus", [
      "setSelectedLineItems",
      "setTotalSelectedLineItems",
      "setLineItemsAllSelected",
      "setPagination",
      "setLineItems",
      "setTotalLineItems",
      "resetAccountStatusFilters",
    ]),
    ...mapMutations("invoiceScreen", ["resetInvoicePagination"]),
    ...mapActions("accountStatus", [
      "getLineItems",
      "getAccountInvoiceLineItemsByAccountIdsAggregate",
    ]),
    ...mapActions("exports", ["filterExport"]),
    ...mapActions("accountStatus", ["updateManyBillingStatuses"]),
    formatBillingRunType(type) {
      return formatBillingRunType(type);
    },
    async confirmUpdate({ newStatus, note }) {
      this.updatingStatuses = true;

      let payload = {
        newStatus: newStatus,
        note: note
      };
      if (this.allSelected) {
        payload = { ...payload, all: true };
        if (!this.feeResultsAllSelected) {
          payload = { ...payload, selectedAccounts: this.selectedAccounts };
        }
      } else {
        payload = { ...payload, selectedItems: this.selected };
      }
      let result = await this.updateManyBillingStatuses(payload);
      let notUpdatedCount = result.notUpdatedInvoicesIds.length;

      if (result.error) {
        this.showUpdateErrorMessage = true;
      } else if (notUpdatedCount > 0) {
        this.showUpdateInfo = true;
        this.updateMessage =
          "There were some problems, " +
          notUpdatedCount +
          " invoices not updated.";
      } else {
        this.updateSuccess = true;
      }
      amplitude.getInstance().logEvent("Fee Review - Account Status [Status Update]", {
        "New Status": newStatus,
      });
      this.updatingStatuses = false;
      this.reset();
      await this.refresh();
    },
    async updateBillingStatuses() {
      this.showUpdateDialog = true;
    },
    async refreshOrCut(pageSize) {
      let array = this.lineItems;
      if (pageSize > array.length) {
        await this.refresh();
      } else {
        this.setLineItems(this.lineItems.slice(0, pageSize));
      }
    },
    async refresh() {
      this.loading = true;
      await this.getAccountInvoiceLineItemsByAccountIdsAggregate({
        page: this.page,
        pageSize: this.pageSize,
        feeResultsAll: this.feeResultsAllSelected
      });
      this.loading = false;
    },
    async generateCustodian() {
      await this.generateExport("Custodian");
    },
    async generatePayout() {
      await this.generateExport("Payout");
    },
    async generateExport(exportType) {
      let payload = {
        runType: "Regular",
        period: getPreviousRunIntFromString(this.selectedDate),
        exportType: exportType
      };
      if (this.allSelected) {
        if (this.feeResultsAllSelected) {
          payload = { ...payload, all: true };
        } else {
          payload = { ...payload, selectedAccounts: this.selectedAccounts };
        }
      } else {
        payload = {
          ...payload,
          selectedItems: this.selected,
          fromAccountStatus: true
        };
      }
      let result = await this.filterExport(payload);

      switch (result.status) {
        case "SUCCESS":
          this.$router.push({ name: "FeeResultsExports" });
          break;
        case "INFO":
          this.exportInfoMessage = result.message;
          this.showExportInfoMessage = true;
          break;
        case "EMPTY":
          this.showEmptyExportsDialog = true;
          break;
        case "WARNING":
          this.exportWarningMessage = result.message;
          this.exportWarningDialog = true;
          break;
        case "TIMEOUT":
          this.exportStartedMessage = `${exportType} Export is taking too long. Check status in Fee Review.`;
          this.exportStarted = true;
          break;
        default:
          this.showExportErrorDialog = true;
      }
    },
    showInvoiceHistory() {
      this.resetInvoicePagination();
      this.$router.push({ name: "InvoiceScreen" });
      amplitude.getInstance().logEvent("Fee Review - Account Status [Show Invoice History]");
    },
    selectNone() {
      this.selected = [];
      this.allSelected = false;
    },
    back() {
      this.resetAccountStatusFilters();
      this.$router.push({ name: "FeeResultsAccounts" });
    },
    formatMonetaryValue(number) {
      return formatMonetaryValue(number);
    },
    reset() {
      this.selected = [];
      this.setLineItemsAllSelected(false);
      this.setLineItems([]);
      this.setTotalLineItems(0);
    },
  },
  async beforeMount() {
    this.reset();
  },
  async mounted() {
    if (this.accountsForCalculation.length == 0 && !this.feeResultsAllSelected) {
      this.$router.replace({ name: "FeeResultsAccounts" });
    } else {
      await this.refresh();
    }
  },
};
</script>

<style>
/*Light Theme*/
/*rows*/
.all-selected.theme--light.v-data-table tbody tr {
  background: #1565c0 !important;
  color: white;
  font-weight: 400;
}
/*rows hovered*/
.all-selected.theme--light.v-data-table tbody tr:hover {
  background: white !important;
  color: black;
}
/*rows selected*/
.all-selected.theme--light.v-data-table tbody tr.v-data-table__selected {
  background: #1565c0 !important;
}
/*rows selected hovered*/
.all-selected.theme--light.v-data-table tbody tr.v-data-table__selected:hover {
  background: white !important;
  color: black;
}

/*Dark Theme*/
/*rows*/
.all-selected.theme--dark.v-data-table tbody tr {
  background: #1565c0 !important;
  color: white;
  font-weight: 400;
}
/*rows hovered*/
.all-selected.theme--dark.v-data-table tbody tr:hover {
  background: white !important;
  color: black;
}
/*rows selected*/
.all-selected.theme--dark.v-data-table tbody tr.v-data-table__selected {
  background: #1565c0 !important;
}
/*rows selected hovered*/
.all-selected.theme--dark.v-data-table tbody tr.v-data-table__selected:hover {
  background: white !important;
  color: black;
}

.theme--light.v-data-table tbody tr.v-data-table__selected {
  background: #26a69a !important;
}
.theme--dark.v-data-table tbody tr.v-data-table__selected {
  background: #26a69a !important;
}
.theme--dark.v-data-table tbody tr.v-data-table__selected:hover {
  background: #26a69a !important;
}
.theme--light.v-data-table tbody tr.v-data-table__selected:hover {
  background: r#26a69aed !important;
}
</style>
